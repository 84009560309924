/*
 * Payment helper
 */
import { first, map } from 'lodash';
export const extractPaymentDetailsData = (paymentResponse) => {
    if (!paymentResponse) {
        return [];
    }
    // console.log(paymentResponse, '::paymentResponse');
    const paymentId = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.id;
    const orderStatus = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.status;
    const orderItems = map(paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.order_items, ({ item_id, order_id, unit_price, quantity, total_price, item: { status: item_status, drive_occurrence_guest: { id: drive_occurrence_guest_id, drive_schedule: { id: drive_schedule_id, name: drive_schedule_name, start_date: drive_schedule_start_date, end_date: drive_schedule_end_date, slot_duration: drive_schedule_slot_duration, status: drive_schedule_status, slot_capacity: drive_schedule_slot_capacity, }, drive_occurrence = {}, }, sku, eligible_refund_rule, }, }) => {
        const { drive_schedule_id: drive_occurrence_id = null, start_date: drive_occurrence_start_date = null, end_date: drive_occurrence_end_date = null, status: drive_occurrence_status = null, workflow_step_id: drive_occurrence_workflow_step_id = null, } = drive_occurrence || {};
        return {
            item_id,
            order_id,
            quantity,
            unit_price,
            total_price,
            item_status,
            eligible_refund_rule,
            drive_occurrence_guest_id,
            drive_schedule_id,
            drive_schedule_name,
            drive_schedule_start_date,
            drive_schedule_end_date,
            drive_schedule_slot_duration,
            drive_schedule_status,
            drive_schedule_slot_capacity,
            drive_occurrence_id,
            drive_occurrence_start_date,
            drive_occurrence_end_date,
            drive_occurrence_status,
            drive_occurrence_workflow_step_id,
            sku_name: sku === null || sku === void 0 ? void 0 : sku.name,
            refund_rules: sku === null || sku === void 0 ? void 0 : sku.refund_rules,
        };
    });
    const transactionDetails = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.transactions.map(({ payment_id, external_id, status, total_amount, type }) => ({
        transactionId: payment_id,
        transactionExternalId: external_id,
        transactionStatus: status,
        transactionTotalAmount: `$${total_amount}`,
        transactionType: type,
    }));
    const formattedOrderDataArray = orderItems.map((orderItem) => {
        const firstTransactionDetail = (transactionDetails === null || transactionDetails === void 0 ? void 0 : transactionDetails.length) > 0 ? transactionDetails[0] : null;
        return Object.assign(Object.assign({}, orderItem), { payment_id: paymentId, order_status: orderStatus, transactionDetails: firstTransactionDetail });
    });
    // console.log(formattedOrderDataArray, '::formattedOrderDataArray');
    return formattedOrderDataArray;
};
export const isValidCouponObject = (obj) => obj &&
    typeof obj.order_id === 'number' &&
    typeof obj.coupon_code === 'string' &&
    obj.order_items &&
    Array.isArray(obj.order_items);
export const formatCouponData = (data) => {
    if (!isValidCouponObject(data)) {
        throw new Error('Invalid coupon data provided');
    }
    const { order_id, coupon_code, order_items } = data;
    const firstOrderItem = first(order_items);
    const { coupon_id: item_couponId = null, id: item_id = null, item_id: item_itemId = null, order_item_id: item_orderItemId = null, unit_price: item_unitPrice = null, quantity: item_quantity = null, total_price: item_totalPrice = null, } = firstOrderItem || {};
    return {
        order_id,
        coupon_code,
        item_couponId,
        item_id,
        item_itemId,
        item_orderId: order_id,
        item_orderItemId,
        item_unitPrice,
        item_quantity,
        item_totalPrice,
        isValid: true,
    };
};
export const redirectToCXSlotBooking = ({ queryParams, tokens }) => {
    if (!queryParams)
        return;
    const { driveScheduleId, tid } = queryParams;
    const { accessToken } = tokens || {};
    // const url = new URL(`${process.env.OLD_CANDIDATE_X_URL}/scheduling/slot-booking&rebookSlot=${true}`);
    const url = new URL(`${process.env.OLD_CANDIDATE_X_URL}/scheduling/slot-booking`);
    url.searchParams.set('driveScheduleId', String(driveScheduleId));
    url.searchParams.set('tid', String(tid));
    // url.searchParams.set('rebookSlot', String(rebookSlot));
    if (accessToken) {
        url.searchParams.set('idToken', accessToken);
    }
    window.open(url.toString(), '_self');
};
