import { createSlice } from '@reduxjs/toolkit';
export const initialState = {};
export const InterviewDeclineSlice = createSlice({
    name: 'interview-decline-scheduling',
    initialState,
    reducers: {
        interviewDecline(_state, _action) { },
    },
});
export const { interviewDecline } = InterviewDeclineSlice.actions;
export default InterviewDeclineSlice.reducer;
