import { call, takeLatest, fork } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { interviewDecline } from './slice';
import { DECLINE_INTERVIEW } from './queries';
import postData from '@utils/postData';
export function* interviewDeclineWorker({ payload }) {
    var _a, _b, _c, _d;
    try {
        const response = yield call(postData, {
            queryString: DECLINE_INTERVIEW,
            payload: payload === null || payload === void 0 ? void 0 : payload.data,
        });
        // @ts-expect-error this will get resolve in another PR
        if (((_a = payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) && ((_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.sch_rsvp_action) === null || _c === void 0 ? void 0 : _c.success_count) > 0) {
            yield call(payload.callback.onSuccess, response);
        }
        else {
            throw new Error('An error occurred while declining the interview');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Decline Interview', error: error, skipToast: true });
        if ((_d = payload.callback) === null || _d === void 0 ? void 0 : _d.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* interviewDeclineWatcher() {
    yield takeLatest(interviewDecline.type, interviewDeclineWorker);
}
export function* interviewDeclineSaga() {
    yield fork(interviewDeclineWatcher);
}
