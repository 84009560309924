import { call, fork, takeLatest, select, put, debounce, delay } from '@redux-saga/core/effects';
import { filter, get } from 'lodash';
import { fetchNextQuestionById, fetchQuestionsList, fetchTcfMeasure, saveAnswer, saveAppState, getAppState, startAssessment, startSection, stopSection, initialize, storeAssessment, storeSection, storeNextQuestion, storeAnswer, storeStopSection, fetchTestInstance, startAssessmentError, storeTcfMeasurement, resetSectionState, submitSection, storeAppState, storeQuestionList, saveBulkAnswer, storeBulkAnswer, } from '@containers/Assessment/slice';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { GET_NEXT_QUESTION_BY_ID, SAVE_ANSWERS, START_ASSESSMENT, START_SECTION, STOP_SECTION, GET_TCF_MEASUREMENT, CREATE_TEST_INSTANCE, CANX_GET_APP_STATE, CANX_UPSERT_APP_STATE, GET_ALL_QUESTION, } from '@containers/Assessment/queries';
import { selectQueryParams } from '@containers/Auth/selectors';
import { localStorageGetItem, localStorageSetItem, removeLocalStorageItem, TEST_SESSION_STORAGE_KEY, } from '@utils/localStorageHelpers';
import { getNextQuestionId, getOverallSections, parseBulkSavedAnswer, parseSubmittedAnswer, processStartAssessmentResponse, processStartSectionResponse, } from '@containers/Assessment/helpers';
import { selectAnswers, selectAppState, selectAllQuestion, selectCurrentQuestion, selectNextQuestionId, selectQuestionIds, selectStartSectionResponse, selectTestInstanceId, } from '@containers/Assessment/selectors';
import { getCurrentDate } from '@utils/dateHelpers';
import fetchData from '@utils/fetchData';
import { storeQueryParams } from '@containers/Auth/slice';
import { find } from 'lodash';
import { bulkAnswerSubmitted } from '@utils/mixpanel/mixpanelActions';
import { stopHeartBeat } from '@containers/App/types';
export function* initializeWorker() {
    try {
        const queryParams = (yield select(selectQueryParams));
        const storedTestSession = (yield call(localStorageGetItem, TEST_SESSION_STORAGE_KEY));
        const parsedStoredTestSession = storedTestSession ? JSON.parse(storedTestSession) : {};
        if (!queryParams.test_instance_id && (parsedStoredTestSession === null || parsedStoredTestSession === void 0 ? void 0 : parsedStoredTestSession.test_instance_id)) {
            yield put(startAssessment({ instance_id: Number(parsedStoredTestSession.test_instance_id) }));
        }
        else if (queryParams.test_instance_id &&
            (parsedStoredTestSession === null || parsedStoredTestSession === void 0 ? void 0 : parsedStoredTestSession.test_instance_id) &&
            Number(queryParams.test_instance_id) !== Number(parsedStoredTestSession === null || parsedStoredTestSession === void 0 ? void 0 : parsedStoredTestSession.test_instance_id)) {
            yield call(removeLocalStorageItem, TEST_SESSION_STORAGE_KEY);
            yield call(localStorageSetItem, TEST_SESSION_STORAGE_KEY, JSON.stringify({ test_instance_id: queryParams.test_instance_id }));
            yield put(startAssessment({ instance_id: Number(queryParams.test_instance_id) }));
        }
        else if (queryParams.test_instance_id) {
            yield call(localStorageSetItem, TEST_SESSION_STORAGE_KEY, JSON.stringify({ test_instance_id: queryParams.test_instance_id }));
            yield put(startAssessment({ instance_id: Number(queryParams.test_instance_id) }));
        }
        else {
            throw Error('No test_instance_id found in queryParams');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Assessment initialize Worker',
            error: error,
        });
    }
}
function* startAssessmentWorker({ payload: { instance_id } }) {
    var _a, _b;
    try {
        const response = (yield call(postData, {
            queryString: START_ASSESSMENT,
            payload: {
                instance_id: Number(instance_id),
            },
        }));
        const result = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.canx_start_assessment[0];
        if (result === null || result === void 0 ? void 0 : result.success) {
            const processedData = (yield call(processStartAssessmentResponse, result.data));
            yield put(storeAssessment(processedData));
        }
        else {
            const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message) ? (_b = result === null || result === void 0 ? void 0 : result.error_message) === null || _b === void 0 ? void 0 : _b[0] : result === null || result === void 0 ? void 0 : result.error_message;
            throw Error(`Failed to start assessment: ${err}`);
        }
    }
    catch (error) {
        const err = error;
        yield call(catchError, {
            title: 'startAssessmentWorker',
            error: err,
        });
        yield put(startAssessmentError({ error: err && (err === null || err === void 0 ? void 0 : err.message) }));
    }
}
export function* startSectionWorker({ payload }) {
    var _a, _b, _c, _d, _e;
    try {
        const testInstanceId = yield select(selectTestInstanceId);
        const response = (yield call(postData, {
            queryString: START_SECTION,
            payload: { instance_id: testInstanceId },
        }));
        const result = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.canx_start_next_section[0];
        if (result === null || result === void 0 ? void 0 : result.success) {
            if ((_b = result.data) === null || _b === void 0 ? void 0 : _b.id) {
                yield call(getAppStateWorker, {
                    payload: {
                        section_id: (_c = result.data) === null || _c === void 0 ? void 0 : _c.id,
                    },
                    type: getAppState.type,
                });
            }
            const appState = (yield select(selectAppState));
            const processedData = (yield call(processStartSectionResponse, result.data, appState));
            if (!processedData.assessmentSection) {
                yield call(payload.callback.onSuccess, {
                    redirectTo: '/assessment/result',
                });
            }
            else if (!processedData.next_question_id) {
                yield put(storeSection(processedData));
                yield call(payload.callback.onSuccess, {
                    redirectTo: '/assessment/submit',
                });
            }
            else {
                yield put(storeSection(processedData));
                yield call(fetchQuestionsListWorker);
                if (payload.callback.onSuccess) {
                    yield call(payload.callback.onSuccess);
                }
            }
        }
        else {
            const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message) ? (_d = result === null || result === void 0 ? void 0 : result.error_message) === null || _d === void 0 ? void 0 : _d[0] : result === null || result === void 0 ? void 0 : result.error_message;
            throw Error(`Failed to start section: ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'startSectionWorker',
            error: error,
        });
        if ((_e = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _e === void 0 ? void 0 : _e.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* getNextSectionWorker({ payload }) {
    var _a, _b, _c;
    try {
        const testInstanceId = yield select(selectTestInstanceId);
        const response = (yield call(postData, {
            queryString: START_SECTION,
            payload: { instance_id: testInstanceId },
        }));
        const result = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.canx_start_next_section[0];
        if (result === null || result === void 0 ? void 0 : result.success) {
            const processedData = (yield call(processStartSectionResponse, result.data));
            if (processedData.assessmentSection) {
                yield put(resetSectionState());
                yield call(payload.callback.onSuccess, {
                    redirectTo: '/assessment/invite',
                });
            }
            else {
                if (window.self !== window.top) {
                    window.parent.postMessage({ exit: true, discardExitConfirmation: true }, '*');
                }
                else {
                    yield call(payload.callback.onSuccess, {
                        redirectTo: '/assessment/feedback',
                    });
                }
            }
        }
        else {
            const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message) ? (_b = result === null || result === void 0 ? void 0 : result.error_message) === null || _b === void 0 ? void 0 : _b[0] : result === null || result === void 0 ? void 0 : result.error_message;
            throw Error(`Failed to start section: ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'startSectionWorker',
            error: error,
        });
        if ((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* fetchNextQuestionByIdWorker({ payload }) {
    var _a, _b, _c, _d, _e;
    try {
        const nextQuestionId = (yield select(selectNextQuestionId));
        if (!nextQuestionId) {
            yield put({ type: stopHeartBeat });
            if (payload.callback.onSuccess) {
                yield call(payload.callback.onSuccess, { redirectTo: '/assessment/submit' });
            }
        }
        else {
            let result;
            const questions = (yield select(selectAllQuestion));
            if (questions === null || questions === void 0 ? void 0 : questions.length) {
                const question = nextQuestionId ? find(questions, { id: nextQuestionId }) : null;
                if (question) {
                    result = {
                        success: true,
                        data: [question],
                    };
                }
                else {
                    result = {
                        error_message: 'Question not found in question list',
                    };
                }
            }
            else {
                const startSectionResponse = (yield select(selectStartSectionResponse));
                const response = (yield call(postData, {
                    queryString: GET_NEXT_QUESTION_BY_ID,
                    payload: {
                        question_id: nextQuestionId,
                        section_id: startSectionResponse === null || startSectionResponse === void 0 ? void 0 : startSectionResponse.id,
                    },
                    spreadPayload: true,
                }));
                result = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.ae_get_next_question;
            }
            if (result === null || result === void 0 ? void 0 : result.success) {
                const questionIds = (yield select(selectQuestionIds));
                const question = (_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b[0];
                const nextQuestionId = getNextQuestionId(questionIds, question === null || question === void 0 ? void 0 : question.id);
                yield put(storeNextQuestion({ data: (_c = result.data) === null || _c === void 0 ? void 0 : _c[0], next_question_id: nextQuestionId }));
                if (payload.callback.onSuccess) {
                    yield call(payload.callback.onSuccess);
                }
            }
            else {
                const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message)
                    ? (_d = result === null || result === void 0 ? void 0 : result.error_message) === null || _d === void 0 ? void 0 : _d[0]
                    : result === null || result === void 0 ? void 0 : result.error_message;
                throw Error(`Failed to start section: ${err}`);
            }
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchNextQuestionByIdWorker',
            error: error,
        });
        if ((_e = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _e === void 0 ? void 0 : _e.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* fetchQuestionsListWorker() {
    var _a, _b;
    try {
        const testInstanceId = yield select(selectTestInstanceId);
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const response = (yield call(postData, {
            queryString: GET_ALL_QUESTION,
            payload: {
                instance_id: testInstanceId,
                section_id: startSectionResponse === null || startSectionResponse === void 0 ? void 0 : startSectionResponse.id,
            },
            spreadPayload: true,
        }));
        if (response) {
            yield put(storeQuestionList({ data: (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.ae_get_question_list) === null || _b === void 0 ? void 0 : _b.data }));
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchQuestionsListWorker',
            error: error,
        });
    }
}
export function* saveAnswerWorker({ payload, }) {
    var _a, _b, _c, _d, _e;
    try {
        const currentQuestion = (yield select(selectCurrentQuestion));
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const questions = (yield select(selectAllQuestion));
        let result;
        if (questions) {
            result = parseSubmittedAnswer(payload, currentQuestion, startSectionResponse);
        }
        else {
            const response = (yield call(postData, {
                queryString: SAVE_ANSWERS,
                payload: {
                    question_id: currentQuestion.id,
                    section_id: startSectionResponse.id,
                    answered_at: (_a = getCurrentDate()) === null || _a === void 0 ? void 0 : _a.toUTC().toISO(),
                    choice: payload.data.choice,
                },
            }));
            result = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.ae_save_answer[0];
        }
        if (result === null || result === void 0 ? void 0 : result.success) {
            yield put(storeAnswer(result.data));
            if (!(typeof ((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.is_correct_choice) === 'boolean')) {
                yield put(fetchNextQuestionById({ callback: payload.callback }));
            }
            else {
                if (payload.callback.onSuccess) {
                    yield call(payload.callback.onSuccess);
                }
            }
        }
        else {
            const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message) ? (_d = result === null || result === void 0 ? void 0 : result.error_message) === null || _d === void 0 ? void 0 : _d[0] : result === null || result === void 0 ? void 0 : result.error_message;
            throw Error(`Failed to save answer:  ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveAnswerWorker',
            error: error,
        });
        if ((_e = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _e === void 0 ? void 0 : _e.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* saveBulkAnswerWorker({ payload }) {
    var _a;
    try {
        const allAnswers = (yield select(selectAnswers));
        const answers = (yield call(filter, allAnswers, (obj) => obj.id === 'has_to_save'));
        const eventOption = {
            Type: 'Manual',
        };
        bulkAnswerSubmitted(eventOption);
        if (answers.length) {
            const formatedAnswerObject = answers.map((item) => ({
                question_id: item.question_id,
                section_id: item.section_id,
                answered_at: item.answered_at,
                choice: item.choice,
            }));
            const response = (yield call(postData, {
                queryString: SAVE_ANSWERS,
                payload: formatedAnswerObject,
            }));
            const parseBulkSavedAnswerResponse = (yield call(parseBulkSavedAnswer, response, allAnswers));
            if (payload.callback.onSuccess && parseBulkSavedAnswerResponse) {
                yield put(storeBulkAnswer(parseBulkSavedAnswerResponse.formattedAnswers));
                yield call(payload.callback.onSuccess, parseBulkSavedAnswerResponse);
            }
        }
        else {
            yield put(stopSection({ callback: payload.callback }));
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveBulkAnswerWorker',
            error: error,
        });
        if ((_a = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _a === void 0 ? void 0 : _a.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* stopSectionWorker({ payload, }) {
    var _a, _b;
    try {
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const response = (yield call(postData, {
            queryString: STOP_SECTION,
            payload: {
                section_id: startSectionResponse.id,
            },
        }));
        const result = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.ae_stop_section[0];
        if (result === null || result === void 0 ? void 0 : result.success) {
            yield put(storeStopSection({ startSectionResponse: result.data }));
            if (payload.callback.onSuccess) {
                if (payload === null || payload === void 0 ? void 0 : payload.async) {
                    yield call(payload.callback.onSuccess, { redirectTo: '/assessment/feedback' });
                }
                else {
                    yield call(payload.callback.onSuccess, { redirectTo: '/assessment/result' });
                }
            }
        }
        else {
            const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message) ? (_b = result === null || result === void 0 ? void 0 : result.error_message) === null || _b === void 0 ? void 0 : _b[0] : result === null || result === void 0 ? void 0 : result.error_message;
            throw Error(`Failed to stop section:  ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'stopSectionWorker',
            error: error,
        });
    }
}
export function* fetchTcfMeasureWorker({ payload: { callback }, }) {
    try {
        const testInstanceId = yield select(selectTestInstanceId);
        const response = (yield call(fetchData, {
            queryString: GET_TCF_MEASUREMENT,
            queryKey: 'ae_get_instance_score',
            queryVariables: { test_instance_id: testInstanceId },
            forceRefresh: true,
            context: {},
        }));
        const result = response[0];
        const overallData = (yield call(getOverallSections, result.data));
        if (!overallData.length) {
            yield delay(5000);
            yield put(fetchTcfMeasure({ callback }));
        }
        else if (callback.onSuccess) {
            yield put(storeTcfMeasurement(overallData));
            yield call(callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchTcfMeasureWorker',
            error: error,
        });
    }
}
export function* saveAppStateWorker({ payload, }) {
    var _a, _b;
    try {
        const { id } = (yield select(selectStartSectionResponse));
        const answers = (yield select(selectAnswers));
        const currentQuestion = (yield select(selectCurrentQuestion));
        const nextQuestionId = yield select(selectNextQuestionId);
        const formatedPayload = {
            section_id: id,
            state: [
                {
                    remaining_time: (_a = payload === null || payload === void 0 ? void 0 : payload.data) === null || _a === void 0 ? void 0 : _a.remaining_time,
                    answers: answers,
                    currentQuestionId: currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.id,
                    nextQuestionId: nextQuestionId,
                },
            ],
        };
        const response = (yield call(postData, {
            queryString: CANX_UPSERT_APP_STATE,
            payload: formatedPayload,
        }));
        const result = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.canx_upsert_app_state[0];
        if (result === null || result === void 0 ? void 0 : result.success) {
            yield put(storeAppState(result === null || result === void 0 ? void 0 : result.data));
        }
        else {
            yield call(catchError, {
                title: 'saveAppStateWorker',
                error: Error('Failed to save the appState to server'),
                extraScope: { key: 'appState', value: JSON.stringify(formatedPayload) },
                skipToast: true,
            });
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveAppStateWorker',
            error: error,
        });
    }
}
export function* createTestInstanceWorker({ payload, }) {
    var _a, _b, _c, _d;
    try {
        const testInstanceId = yield select(selectTestInstanceId);
        if (testInstanceId) {
            const response = (yield call(postData, {
                queryString: CREATE_TEST_INSTANCE,
                payload: {
                    test_instance_id: Number(testInstanceId),
                    reattempt: true,
                },
                spreadPayload: true,
            }));
            const result = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.canx_create_test_instance[0];
            if (result === null || result === void 0 ? void 0 : result.success) {
                const queryParams = (yield select(selectQueryParams));
                const test_instance_id = (_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.test_instance_id;
                if (test_instance_id) {
                    yield put(storeQueryParams(Object.assign(Object.assign({}, queryParams), { test_instance_id: String(test_instance_id) })));
                    yield put(initialize());
                    if (payload.callback.onSuccess) {
                        yield call(payload.callback.onSuccess);
                    }
                }
                else {
                    throw Error('createTestInstanceWorker: Test instance id not found in the reattempt response');
                }
            }
            else {
                const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message)
                    ? (_c = result === null || result === void 0 ? void 0 : result.error_message) === null || _c === void 0 ? void 0 : _c[0]
                    : result === null || result === void 0 ? void 0 : result.error_message;
                throw Error(`Failed to createTestInstance: ${err}`);
            }
        }
        else {
            throw Error('createTestInstance: No test instance id found');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'createTestInstance',
            error: error,
        });
        if ((_d = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _d === void 0 ? void 0 : _d.onError)
            yield call(payload.callback.onError, error);
    }
}
export function* getAppStateWorker({ payload, }) {
    var _a;
    try {
        if (payload === null || payload === void 0 ? void 0 : payload.section_id) {
            const response = (yield call(fetchData, {
                queryString: CANX_GET_APP_STATE,
                queryVariables: {
                    section_id: Number(payload === null || payload === void 0 ? void 0 : payload.section_id),
                },
            }));
            const result = get(response, 'canx_get_app_state.0', null);
            if (result === null || result === void 0 ? void 0 : result.section_id) {
                yield put(storeAppState(result));
                if ((_a = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) {
                    yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onSuccess);
                }
            }
        }
        else {
            throw Error('No Section id found');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'getAppStateInstance',
            error: error,
        });
    }
}
export function* initializeWatcher() {
    yield debounce(1000, initialize.type, initializeWorker);
}
export function* startAssessmentWatcher() {
    yield takeLatest(startAssessment.type, startAssessmentWorker);
}
export function* startSectionWatcher() {
    yield debounce(1000, startSection.type, startSectionWorker);
}
export function* getNextSectionWatcher() {
    yield takeLatest(submitSection.type, getNextSectionWorker);
}
export function* fetchNextQuestionByIdWatcher() {
    yield takeLatest(fetchNextQuestionById.type, fetchNextQuestionByIdWorker);
}
export function* fetchQuestionsListWatcher() {
    yield takeLatest(fetchQuestionsList.type, fetchQuestionsListWorker);
}
export function* saveAnswerWatcher() {
    yield takeLatest(saveAnswer.type, saveAnswerWorker);
}
export function* stopSectionWatcher() {
    yield takeLatest(stopSection.type, stopSectionWorker);
}
export function* fetchTcfMeasureWatcher() {
    yield debounce(1000, fetchTcfMeasure.type, fetchTcfMeasureWorker);
}
export function* saveAppStateWatcher() {
    yield debounce(2000, saveAppState.type, saveAppStateWorker);
}
export function* createTestInstanceWatcher() {
    yield takeLatest(fetchTestInstance.type, createTestInstanceWorker);
}
export function* getAppStateWatcher() {
    yield takeLatest(getAppState.type, getAppStateWorker);
}
export function* saveBulkAnswerWatcher() {
    yield debounce(1000, saveBulkAnswer.type, saveBulkAnswerWorker);
}
export function* assessmentRootSaga() {
    yield fork(initializeWatcher);
    yield fork(startAssessmentWatcher);
    yield fork(startSectionWatcher);
    yield fork(fetchNextQuestionByIdWatcher);
    yield fork(fetchQuestionsListWatcher);
    yield fork(saveAnswerWatcher);
    yield fork(stopSectionWatcher);
    yield fork(fetchTcfMeasureWatcher);
    yield fork(saveAppStateWatcher);
    yield fork(createTestInstanceWatcher);
    yield fork(getNextSectionWatcher);
    yield fork(getAppStateWatcher);
    yield fork(saveBulkAnswerWatcher);
}
