import { gql } from '@apollo/client';
export const UPDATE_CANDIDATE_DETAILS_MUTATION = gql `
  mutation updateCandidateDetails($payload: [can_update_candidate_input]!) {
    can_update_candidate(data: $payload) {
      data {
        country
        email
        external_id
        is_active
        first_name
        id
        is_tnc_accepted
        last_name
        middle_name
        old_id
        user_id
        phone_number
        tenant_id
        is_recording_consent_accepted
      }
      error_message
      success
    }
  }
`;
