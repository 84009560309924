import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    driveData: null,
    loading: false,
    error: null,
    confirmLoading: false,
    confirmSuccess: false,
    driveOccurrence: null,
};
export const driveSlice = createSlice({
    name: 'driveDetails',
    initialState,
    reducers: {
        fetchDriveDetails: (state, _action) => {
            state.loading = true;
        },
        driveDetailsSuccess: (state, action) => {
            state.loading = false;
            state.driveData = action.payload;
        },
        confirmDriveSlotRequest: (state, _action) => {
            state.confirmLoading = true;
        },
        confirmDriveSlotSuccess: (state) => {
            state.confirmLoading = false;
            state.confirmSuccess = true;
        },
        confirmDriveSlotFailure: (state, action) => {
            state.confirmLoading = false;
            state.confirmError = action.payload;
        },
        fetchDriveOccurrenceDetails: (state, _action) => {
            state.loading = true;
        },
        driveOccurrenceDetailsSuccess: (state, action) => {
            state.loading = false;
            state.driveOccurrence = action.payload;
            state.error = null;
        },
        cancelDriveOccurrenceGuestRequest: (state, _action) => {
            state.loading = true;
        },
        cancelDriveOccurrenceGuestSuccess: (state, _action) => {
            state.loading = false;
        },
        registerCandidateToDriveRequest: (_state, _action) => { },
        registerCandidateToDriveSuccess: (_state, _action) => { },
    },
});
export const { fetchDriveDetails, driveDetailsSuccess, confirmDriveSlotRequest, confirmDriveSlotSuccess, confirmDriveSlotFailure, fetchDriveOccurrenceDetails, driveOccurrenceDetailsSuccess, cancelDriveOccurrenceGuestRequest, cancelDriveOccurrenceGuestSuccess, registerCandidateToDriveRequest, registerCandidateToDriveSuccess, } = driveSlice.actions;
export default driveSlice.reducer;
