import { call, takeLatest, fork, put } from 'redux-saga/effects';
import { first } from 'lodash';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
import { handleSagaError, handleSagaSuccess } from '@utils/sagaHelper';
import { catchException, processSagaError } from '@utils/catchException';
import { CONFIRM_DRIVE_MEETING_SLOT, GET_GUEST_OCCURRENCE_ID, GET_DRIVE_OCCURRENCE, CANCEL_DRIVE_OCCURRENCE_GUEST, } from './queries';
import { cancelDriveOccurrenceGuestRequest, cancelDriveOccurrenceGuestSuccess, confirmDriveSlotRequest, confirmDriveSlotSuccess, driveDetailsSuccess, driveOccurrenceDetailsSuccess, fetchDriveDetails, fetchDriveOccurrenceDetails, } from './slice';
export function* fetchDriveOccurrenceGuestWorker(action) {
    const { driveOccurrenceGuestId, callback } = action.payload;
    try {
        const driveDetailResponse = (yield call(fetchData, {
            queryString: GET_GUEST_OCCURRENCE_ID,
            queryVariables: { id: driveOccurrenceGuestId },
            queryKey: 'sch_drive_occurrence_guest',
            forceRefresh: true,
        }));
        if (!Array.isArray(driveDetailResponse) || driveDetailResponse.length === 0) {
            throw catchException.apiError('No data found while fetching Drive details', {
                driveOccurrenceGuestId,
            });
        }
        const firstDriveOccurrence = first(driveDetailResponse);
        if (!firstDriveOccurrence) {
            throw catchException.validationError('The first drive occurrence has undefined properties.', {
                driveOccurrenceGuestId,
                driveDetailResponse,
            });
        }
        yield put(driveDetailsSuccess(firstDriveOccurrence));
        yield call(handleSagaSuccess, {
            callback: callback === null || callback === void 0 ? void 0 : callback.onSuccess,
            response: driveDetailResponse,
        });
    }
    catch (error) {
        const processedError = processSagaError(error, 'Error in Fetch Drive Occurrence Guest Worker');
        yield call(handleSagaError, {
            callback: callback === null || callback === void 0 ? void 0 : callback.onError,
            error: processedError,
            title: 'Fetch Drive Details Worker Error',
            skipToast: true,
        });
    }
}
export function* confirmDriveSlotWorker(action) {
    var _a, _b, _c;
    const { driveSlotPayloadData, callback } = action.payload;
    const { drive_id, from, to } = driveSlotPayloadData;
    try {
        const response = (yield call(postData, {
            queryString: CONFIRM_DRIVE_MEETING_SLOT,
            payload: { drive_id, from, to },
            spreadPayload: true,
        }));
        const confirmDriveResponse = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.sch_confirm_drive_slot) === null || _b === void 0 ? void 0 : _b[0];
        if (confirmDriveResponse.success) {
            yield put(confirmDriveSlotSuccess());
            if ((_c = confirmDriveResponse === null || confirmDriveResponse === void 0 ? void 0 : confirmDriveResponse.data) === null || _c === void 0 ? void 0 : _c.id) {
                // Dispatch action to trigger `fetchDriveOccurrenceGuestWorker`
                yield put({
                    type: fetchDriveDetails.type,
                    payload: {
                        driveOccurrenceGuestId: confirmDriveResponse.data.id,
                        callback: {
                            onSuccess: function* () {
                                yield call(handleSagaSuccess, {
                                    callback: callback === null || callback === void 0 ? void 0 : callback.onSuccess,
                                    response: confirmDriveResponse,
                                });
                            },
                            onError: function* (error) {
                                yield call(handleSagaError, {
                                    callback: callback === null || callback === void 0 ? void 0 : callback.onError,
                                    error,
                                    title: 'Fetch Drive Details Error',
                                });
                            },
                        },
                    },
                });
            }
            else {
                throw catchException.validationError('Missing driveOccurrenceGuestId in confirmDriveResponse', {
                    response: confirmDriveResponse,
                });
            }
        }
        else {
            throw catchException.apiError(confirmDriveResponse.error_message || 'Failed to confirm drive slot.', {
                response: confirmDriveResponse,
            });
        }
    }
    catch (error) {
        const processedError = processSagaError(error, 'Error in Confirm Drive Slot Worker');
        yield call(handleSagaError, {
            callback: callback === null || callback === void 0 ? void 0 : callback.onError,
            error: processedError,
            title: processedError.message,
            skipToast: true,
        });
    }
}
export function* fetchDriveOccurrenceDetailsSagaWorker(action) {
    const { driveScheduleId, callback } = action.payload;
    try {
        const response = (yield call(fetchData, {
            queryString: GET_DRIVE_OCCURRENCE,
            queryVariables: { drive_schedule_id: driveScheduleId },
            queryKey: 'sch_drive_occurrence',
            forceRefresh: true,
        }));
        if (response && response.length) {
            yield put(driveOccurrenceDetailsSuccess(response));
            yield call(handleSagaSuccess, { callback: callback === null || callback === void 0 ? void 0 : callback.onSuccess, response: response });
        }
        else {
            throw catchException.validationError('No source drive details found for the provided schedule ID.', {
                driveScheduleId,
            });
        }
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: callback === null || callback === void 0 ? void 0 : callback.onError,
            error: processSagaError(error, 'An error occurred in fetchDriveOccurrenceDetailsSagaWorker'),
            title: 'Fetch Drive Details Error',
            skipToast: true,
        });
    }
}
export function* cancelDriveOccurrenceGuestSagaWorker(action) {
    var _a;
    const { driveOccurrenceGuestId, callback } = action.payload;
    try {
        const response = (yield call(postData, {
            queryString: CANCEL_DRIVE_OCCURRENCE_GUEST,
            payload: { driveOccurrenceGuestId },
            spreadPayload: true,
        }));
        if (response) {
            const successPayload = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.canx_cancel_drive_occurrence_guest;
            yield put(cancelDriveOccurrenceGuestSuccess(successPayload));
            yield call(handleSagaSuccess, { callback: callback === null || callback === void 0 ? void 0 : callback.onSuccess, response: successPayload });
        }
        else {
            throw catchException.validationError('error while cancel the drive with ID', {
                driveOccurrenceGuestId,
            });
        }
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: callback === null || callback === void 0 ? void 0 : callback.onError,
            error: processSagaError(error, `Failed to cancel drive occurrence with ID ${driveOccurrenceGuestId}`),
            title: 'Cancel Drive Details',
            skipToast: true,
        });
    }
}
export function* fetchDriveOccurrenceSagaWatcher() {
    yield takeLatest(fetchDriveDetails.type, fetchDriveOccurrenceGuestWorker);
}
export function* confirmDriveSlotSagaWatcher() {
    yield takeLatest(confirmDriveSlotRequest.type, confirmDriveSlotWorker);
}
export function* fetchDriveOccurrenceDetailsSagaWatcher() {
    yield takeLatest(fetchDriveOccurrenceDetails.type, fetchDriveOccurrenceDetailsSagaWorker);
}
export function* cancelDriveOccurrenceGuestSagaWatcher() {
    yield takeLatest(cancelDriveOccurrenceGuestRequest.type, cancelDriveOccurrenceGuestSagaWorker);
}
export function* driveRootSaga() {
    yield fork(fetchDriveOccurrenceSagaWatcher);
    yield fork(confirmDriveSlotSagaWatcher);
    yield fork(fetchDriveOccurrenceDetailsSagaWatcher);
    yield fork(cancelDriveOccurrenceGuestSagaWatcher);
}
