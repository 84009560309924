import { put, call, takeLatest, fork, all } from 'redux-saga/effects';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { bookSlotRequest, fetchMeetingSlotsRequest, fetchMeetingSlotsSuccess } from './slice';
import { BOOK_MEETING_SLOT, GET_MEETING_SLOTS } from './queries';
export function* fetchMeetingSlotsWorker({ payload }) {
    var _a, _b, _c, _d;
    try {
        const { meetingId } = payload.data;
        const queryResponse = (yield call(postData, {
            queryString: GET_MEETING_SLOTS,
            payload: { meeting_id: meetingId },
            spreadPayload: true,
        }));
        const meetingSlotsResponse = (_b = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.sch_get_meeting_slots) === null || _b === void 0 ? void 0 : _b[0];
        if (meetingSlotsResponse) {
            yield put(fetchMeetingSlotsSuccess(meetingSlotsResponse.data.slots));
            if ((_c = payload.callback) === null || _c === void 0 ? void 0 : _c.onSuccess) {
                yield call(payload.callback.onSuccess, meetingSlotsResponse.data);
            }
        }
        else {
            throw new Error('An error while fetching meeting slots.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Meeting Slots Worker', error: error });
        if ((_d = payload.callback) === null || _d === void 0 ? void 0 : _d.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* bookSlotSaga({ payload }) {
    var _a, _b, _c, _d, _e, _f;
    try {
        const response = (yield call(postData, {
            queryString: BOOK_MEETING_SLOT,
            payload: payload === null || payload === void 0 ? void 0 : payload.data,
            spreadPayload: true,
        }));
        if (((_a = payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) && ((_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.sch_confirm_meeting_slot) === null || _c === void 0 ? void 0 : _c.message) === 'Success') {
            yield call(payload.callback.onSuccess, (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.sch_confirm_meeting_slot);
        }
        else {
            throw new Error(`An error occurred while booking a slot ${(_e = response === null || response === void 0 ? void 0 : response.errors[0]) === null || _e === void 0 ? void 0 : _e.message}`);
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Meeting Slots booking', error: error });
        if ((_f = payload.callback) === null || _f === void 0 ? void 0 : _f.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
// Watcher saga for fetchMeetingSlotsRequest
export function* watchFetchMeetingSlots() {
    yield takeLatest(fetchMeetingSlotsRequest.type, fetchMeetingSlotsWorker);
}
export function* bookSlotWatcherSaga() {
    yield takeLatest(bookSlotRequest.type, bookSlotSaga);
}
export function* meetingSlotsRootSaga() {
    yield all([fork(watchFetchMeetingSlots), fork(bookSlotWatcherSaga)]);
}
