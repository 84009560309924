import { put, call, takeLatest, fork, select } from 'redux-saga/effects';
import { selectQueryParams } from '@containers/Auth/selectors';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { REGISTER_CANDIDATE_TO_DRIVE } from './queries';
import { registerCandidateToDriveRequest, registerCandidateToDriveSuccess } from '../slice';
import { first, get } from 'lodash';
import { localStorageSetItem, PAYMENT_DETAILS } from '@utils/localStorageHelpers';
export function* registerCandidateToDriveWorker({ payload }) {
    var _a, _b, _c, _d, _e;
    try {
        const queryParams = (yield select(selectQueryParams));
        const queryResponse = (yield call(postData, {
            queryString: REGISTER_CANDIDATE_TO_DRIVE,
            payload: {
                drive_schedule_id: queryParams.driveScheduleId && Number(queryParams.driveScheduleId),
            },
        }));
        const registerGuestResponse = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.sch_register_guest;
        if (registerGuestResponse === null || registerGuestResponse === void 0 ? void 0 : registerGuestResponse.success_count) {
            const schRegisterGuest = first(registerGuestResponse.data);
            const orderItem = first((_c = (_b = schRegisterGuest === null || schRegisterGuest === void 0 ? void 0 : schRegisterGuest.drive_occurrence_guest) === null || _b === void 0 ? void 0 : _b.pay_item) === null || _c === void 0 ? void 0 : _c.order_items);
            const orderId = get(orderItem, 'order_id');
            if (orderId) {
                yield call(localStorageSetItem, PAYMENT_DETAILS, String(orderId));
            }
            yield put(registerCandidateToDriveSuccess({ orderId: Number(orderId) }));
            if ((_d = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _d === void 0 ? void 0 : _d.onSuccess) {
                yield call(payload.callback.onSuccess, { orderId });
            }
        }
        else {
            throw new Error(JSON.stringify(registerGuestResponse) || 'An error occurred while registering the candidate to drive.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'registerCandidateToDriveWorker', error: error, skipToast: true });
        if ((_e = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _e === void 0 ? void 0 : _e.onError) {
            yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onError, error);
        }
    }
}
export function* registerCandidateToDriveWatcher() {
    yield takeLatest(registerCandidateToDriveRequest, registerCandidateToDriveWorker);
}
export function* rootDriveRegistrationSaga() {
    yield fork(registerCandidateToDriveWatcher);
}
