import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { UPDATE_CANDIDATE_DETAILS_MUTATION } from './queries';
import { updateCandidateRequest, updateCandidateSuccess } from './slice';
export function* updateCandidateSagaWorker({ payload }) {
    var _a, _b, _c, _d, _e;
    try {
        const { data } = payload;
        const queryResponse = (yield call(postData, {
            queryString: UPDATE_CANDIDATE_DETAILS_MUTATION,
            payload: data,
        }));
        const candidateData = (_b = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.can_update_candidate) === null || _b === void 0 ? void 0 : _b[0];
        if (candidateData.success) {
            yield put(updateCandidateSuccess({ data: candidateData.data }));
            if ((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onSuccess) {
                yield call((_d = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _d === void 0 ? void 0 : _d.onSuccess, candidateData.data);
            }
        }
        else {
            throw new Error(candidateData.error_message || 'An error occurred while updating candidate details.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Update candidate worker', error: error });
        if ((_e = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _e === void 0 ? void 0 : _e.onError) {
            yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onError, error);
        }
    }
}
export function* updateCandidateWatcher() {
    yield takeLatest(updateCandidateRequest.type, updateCandidateSagaWorker);
}
export function* updateCandidateRootSaga() {
    yield fork(updateCandidateWatcher);
}
