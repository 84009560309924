import { gql } from '@apollo/client';
export const GET_ORDER_DETAILS_QUERY = gql `
  query canxGetOrderDetails($order_id: Int!) {
    canx_get_order_details(order_id: $order_id) {
      id
      status
      order_items {
        item_id
        order_id
        unit_price
        total_price
        quantity
        item {
          id
          status
          eligible_refund_rule {
            refund_percent
            timeframe
          }
          drive_occurrence_guest {
            id
            drive_schedule {
              id
              booking_end_date
              booking_start_date
              drive_type
              end_date
              name
              start_date
              status
              timezone
              workflow_step_id
              location
              slot_capacity
              slot_duration
            }
            drive_occurrence {
              drive_schedule_id
              end_date
              start_date
              status
              workflow_step_id
            }
          }
          sku {
            name
            refund_rules {
              refund_percent
              timeframe
            }
          }
        }
      }
      transactions {
        payment_id
        external_id
        status
        total_amount
        type
      }
    }
  }
`;
export const CREATE_TRANSACTION_MUTATION = gql `
  mutation createTransaction($orderId: Int!, $coupon_code: String) {
    pay_create_transaction(order_id: $orderId, coupon_code: $coupon_code) {
      order_id
      payment_id
      transaction_external_id
      payment_gateway_type
      payment_url
    }
  }
`;
export const VALIDATE_COUPON_MUTATION = gql `
  mutation validateCoupon($orderId: Int!, $couponCode: String!) {
    pay_validate_coupon(order_id: $orderId, coupon_code: $couponCode) {
      order_id
      coupon_code
      order_items {
        id
        order_item_id
        item_id
        unit_price
        order_id
        coupon_id
        quantity
        total_price
      }
    }
  }
`;
