import { call, takeLatest, fork } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { DECLINE_PROPSED_TIME } from './queries';
import postData from '@utils/postData';
import { declineNewTime } from './slice';
export function* declineProposedTime({ payload }) {
    var _a, _b;
    try {
        const queryVariables = payload === null || payload === void 0 ? void 0 : payload.data;
        yield call(postData, {
            queryString: DECLINE_PROPSED_TIME,
            payload: queryVariables,
            spreadPayload: true,
        });
        if ((_a = payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
        else {
            throw new Error('An error occurred while declining the proposed time');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Decline Time', error: error });
        if ((_b = payload.callback) === null || _b === void 0 ? void 0 : _b.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* declineProposedTimeWatcher() {
    yield takeLatest(declineNewTime.type, declineProposedTime);
}
export function* declinePropsedTimeSaga() {
    yield fork(declineProposedTimeWatcher);
}
