var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { catchError } from '@utils/sentry';
import { call } from 'redux-saga/effects';
import { runSaga } from 'redux-saga';
import { triggerToast } from '@components/base/Notification';
export function* handleSagaSuccess({ callback, response, }) {
    try {
        if (callback) {
            yield call(callback, response !== null && response !== void 0 ? response : {});
        }
    }
    catch (error) {
        triggerToast({
            message: {
                title: `Error in handling saga success response`,
                summary: error.message || `Unknown error occurred: ${error}`,
            },
            variant: 'danger',
        });
    }
}
export function* handleSagaError({ callback, error, title = 'Saga Title Error', skipToast = false, }) {
    try {
        if (callback) {
            yield call(callback, error);
        }
        yield call(catchError, { title, error, skipToast });
    }
    catch (nestedError) {
        triggerToast({
            message: {
                title: `Error in handling saga error`,
                summary: nestedError.message || `Unknown error occurred while processing error handling: ${nestedError}`,
            },
            variant: 'danger',
        });
    }
}
const testSagaExecution = (saga, initialAction, mockResponse) => __awaiter(void 0, void 0, void 0, function* () {
    const dispatched = [];
    yield runSaga({
        dispatch: (action) => dispatched.push(action),
        getState: () => mockResponse || {},
    }, saga, initialAction).toPromise();
    return dispatched;
});
export default testSagaExecution;
