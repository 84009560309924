// helpers.ts
import { getFormattedMeetingStatus, meetingStatus } from '@utils/data/meetingStatus';
import { getCurrentDate, parseDateTime } from '@utils/dateHelpers';
import { getFormattedRsvpStatus, rsvpStatus } from '@utils/data/rsvpStatus';
import { meetingParticipantRole } from '@utils/data/roles';
import { includes, find } from 'lodash';
export const findParticipant = (meeting, userId, participantId) => {
    return (find(meeting === null || meeting === void 0 ? void 0 : meeting.meeting_participants, (p) => {
        var _a, _b;
        if (participantId) {
            return (p === null || p === void 0 ? void 0 : p.id) === participantId && ((_a = p === null || p === void 0 ? void 0 : p.guest) === null || _a === void 0 ? void 0 : _a.user_id) === userId;
        }
        return ((_b = p === null || p === void 0 ? void 0 : p.guest) === null || _b === void 0 ? void 0 : _b.user_id) === userId && p.participant_role === (meetingParticipantRole === null || meetingParticipantRole === void 0 ? void 0 : meetingParticipantRole.GUEST);
    }) || null);
};
export const getIsMeetingStarted = (meeting) => {
    var _a;
    const status = meeting && ((_a = getFormattedMeetingStatus(meeting === null || meeting === void 0 ? void 0 : meeting.meeting_status)) === null || _a === void 0 ? void 0 : _a.key);
    const meetingStartTime = parseDateTime(meeting.from);
    const searchParams = window.location.pathname;
    const isJoinInterviewPresent = searchParams.includes('join-interview');
    if (isJoinInterviewPresent) {
        return false;
    }
    if (meetingStartTime && meetingStartTime < getCurrentDate()) {
        return true;
    }
    return !includes([meetingStatus === null || meetingStatus === void 0 ? void 0 : meetingStatus.SCHEDULED, meetingStatus === null || meetingStatus === void 0 ? void 0 : meetingStatus.DRAFT, meetingStatus === null || meetingStatus === void 0 ? void 0 : meetingStatus.CANCELLED, meetingStatus === null || meetingStatus === void 0 ? void 0 : meetingStatus.COMPLETED], status);
};
export const getIsMeetingCancelled = (meeting) => {
    var _a;
    return ((_a = getFormattedMeetingStatus(meeting.meeting_status)) === null || _a === void 0 ? void 0 : _a.key) === meetingStatus.CANCELLED;
};
export const getIsMeetingCompleted = (meeting) => {
    var _a;
    const status = (_a = getFormattedMeetingStatus(meeting.meeting_status)) === null || _a === void 0 ? void 0 : _a.key;
    const endTimestamp = parseDateTime(meeting.to);
    const startTimestamp = parseDateTime(meeting.from);
    if (startTimestamp && endTimestamp) {
        const endTimeWithBuffer = endTimestamp.plus({ minutes: 35 });
        return endTimeWithBuffer < getCurrentDate();
    }
    // Fallback to checking the status if timestamps are not available
    return status === meetingStatus.COMPLETED;
};
export const getIsMeetingDeclined = (participant) => {
    var _a;
    return ((_a = getFormattedRsvpStatus(participant === null || participant === void 0 ? void 0 : participant.rsvp_status)) === null || _a === void 0 ? void 0 : _a.key) === rsvpStatus.DECLINED;
};
export function getMeetingStates(meeting, participant) {
    return {
        isMeetingStarted: getIsMeetingStarted(meeting),
        isMeetingCancelled: getIsMeetingCancelled(meeting),
        isMeetingCompleted: getIsMeetingCompleted(meeting),
        isMeetingDeclined: getIsMeetingDeclined(participant),
    };
}
export const meetingType = (meeting, userId, participantId, participant) => {
    const authorizedUser = findParticipant(meeting, userId, participantId);
    const { isMeetingStarted, isMeetingCancelled, isMeetingCompleted, isMeetingDeclined } = getMeetingStates(meeting, participant);
    if (authorizedUser) {
        if (isMeetingCancelled) {
            return 'already-cancelled';
        }
        if (isMeetingCompleted) {
            return 'already-completed';
        }
        if (isMeetingDeclined) {
            return 'already-declined';
        }
        if (isMeetingStarted) {
            return 'already-started';
        }
        return 'accept';
    }
    return 'not-participant';
};
