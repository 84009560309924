export class AppError extends Error {
    constructor(message, details, type = 'UnknownError', statusCode) {
        super(message);
        this.message = message;
        this.details = details;
        this.type = type;
        this.statusCode = statusCode;
        this.name = type;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AppError);
        }
    }
}
export function createError(message, details, type = 'UnknownError', statusCode) {
    return new AppError(message, details, type, statusCode);
}
export const catchException = {
    validationError: (message, details) => createError(message, details, 'ValidationError'),
    apiError: (message, details, statusCode = 500) => createError(message, details, 'APIError', statusCode),
    unknownError: (message, details) => createError(message, details, 'UnknownError'),
};
export function processSagaError(error, contextMessage) {
    const details = typeof error === 'object' && error !== null ? { originalError: error } : { originalError: `${error}` };
    return error instanceof AppError ? error : catchException.unknownError(contextMessage, details);
}
