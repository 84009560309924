export const growthBookFeatureFlags = {
    PLATFORM_LOGO_URL: 'logo_url',
    ALLOW_ASSESSMENT_REATTEMPT: 'cx_allow_assessment_reattempt',
    DISPLAY_ASSESSMENT_SCORE: 'display_assessment_score',
    SHOW_ANSWER_FEEDBACK: 'show_answer_feedback',
    MIX_PANEL_ENABLED: 'mixpanel_enabled',
    DISABLED_COPY_PASTE: 'cx3_copy_paste',
    WATER_MARK_ENABLED: 'watermark_content',
    ALLOW_QUESTION_RETAKE: 'allow_question_retake',
    RECORDING_PLAYBACK_ENABLED: 'recording_playback_enabled',
    PAY_AND_BOOK: 'pay_and_book',
    SKIP_PAYMENT: 'skip_payment',
    DRIVE_REGISTRATION_FORM: 'drive_registration_form',
};
