import { gql } from '@apollo/client';
export const DECLINE_PROPSED_TIME = gql(`
    mutation declineProposedTime($meeting_participant_id: Int!) {
  sch_decline_proposed_time(
    data: {meeting_participant_id: $meeting_participant_id}
  ) {
    data {
      meeting_participant_id
      proposed_new_time
    }
    error_count
    success_count
  }
}
`);
