import { createSlice } from '@reduxjs/toolkit';
export const initialState = {};
export const DeclineProposedTimeSlice = createSlice({
    name: 'declineProposedTime',
    initialState,
    reducers: {
        declineNewTime(_state, _action) { },
    },
});
export const { declineNewTime } = DeclineProposedTimeSlice.actions;
export default DeclineProposedTimeSlice.reducer;
